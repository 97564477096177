// Site stylesheets

// Imports
@import '../jquery.inputs/jquery.inputs.less';
@import '../jquery.jcarousel/jquery.jcarousel.less';
@import '../jquery.uniloader/jquery.uniloader.less';

// Local variables
@nav-toolbar-icons:
  // icon-and-class-name padding-left
  help 20,
  login 26,
  register 25,
  user 23,
  logout 23;
@platform-right-width: 758px;
@platform-title-icon-height: 52px;
@platform-title-icon-margin-bottom: 25px;
@platform-title-max-lines: 2;
@social-icon-height: 27px;

// Local mixins

// Customized global styles
body {
  .isDesktop & {
    .set-value(padding-top; @header-height);
  }

  &.secondary {
    #content {
      background: @lblue;
      .set-value(padding-bottom; (@footer-height + 50));
    }

    h1,
    .h1 {
      background: 0;
      .set-value(padding; 0; margin-bottom; 35);
    }
  }
}
.h3-block {
  .margin-bottom(60);

  &:only-child {
    .margin-bottom(0);
  }

  h3,
  .h3 {
    background: none;
    border-left: 5px solid @blue;
    .set-value(padding; 1 0 1 23; margin; 0);
    text-transform: uppercase;
  }
  .h3-caption {
    .set-value(margin-top; 20);
    .font-size(18);
  }
}
.image-bordered {
  box-shadow: 0 25px 46px 0 rgba(23, 23, 24, .11);
}


// Header
header {
  .isDesktop & {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
  }
}

// Main navigation
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  .set-value(height; @header-height);

  li {
    list-style: none;
  }
}

// Footer
footer {}


// Blocks
.logo {
  display: block;
  float: left;
  .set-value(margin; 34 35 0 0);
  text-decoration: none;

  footer & {
    .set-value(width; 200; margin-top; 0);

    span {
      display: block;
      .set-value(margin-top; 30);
      line-height: @paragraph-line-height;
      color: @grey;
    }
  }
}

.nav-list {
  li {
    display: inline-block;
    .set-value(padding-left; 70);

    .nav-toolbar& {
      .set-value(padding; 13 0 0 30);
    }

    a {
      display: block;
      border-bottom: 3px solid transparent;
      .padding-bottom(27);
      .font-size(15);
      color: @white;
      text-decoration: none;

      &:hover,
      &.active {
        border-color: @blue;
      }

      .nav-toolbar& {
        background-repeat: no-repeat;
        background-position: 0 0;
        border: 0;
        padding: 0;
        .font-size(13; 18px);
        color: #ADADAD;

        .for-each(@pair in @nav-toolbar-icons) {
          @name: at(@pair, 1);
          @pl: at(@pair, 2);
          &.nav-toolbar-@{name} {
            background-image: url('../images/icon-@{name}.png');
            .set-value(padding-left; @pl);
          }
        }

        span {
          border-bottom: 1px solid;
          transition: all .3s;
        }
        &.js-link span {
          border-bottom: 1px dashed;
        }

        &:hover,
        &.active {
          span {
            border-color: transparent;
          }
        }
        &.active {
          color: @blue !important;
        }
      }
    }
  }
}

.mblock {
  > .wrapper {
    .set-value(padding-top; 120; padding-bottom; 120);
  }
  &-left { // .mblock-left
    box-sizing: border-box;
    float: left;
    .set-value(width; 43%; padding-right; 50);

    .text-container {
      .set-value(padding-right; 10);
    }
  }
  &-right { // .mblock-right
    float: left;
    .set-value(width; 57%);

    .text-container {
      padding-right: 20%;
    }
  }
  .text-container:last-child {
    .margin-bottom(0);
  }

  &-with-background { // .mblock-with-background
    display: flex;
    align-items: center;

    > .wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &-blue { // .mblock-blue
    background: @lblue;
  }
}

#promo,
#faq,
#inner {
  background: url('../images/main-bg.jpg') no-repeat 50% 0;
  .set-value(height; 674);
  color: #FEFDFD;
  .text-center;

  .text-container {
    .margin-bottom(70);
    .font-size(18; @paragraph-line-height);
  }
}
#faq {
  > .wrapper {
    .set-value(padding; 0 155);
  }
}
#inner {
  background: url('../images/inner-bg.jpg') no-repeat 50% 0;
  .set-value(height; 319; margin-bottom; 55);

  .text-container {
    .margin-bottom(0);
  }
}

#platform {
  background: url('../images/platform-bg.jpg') no-repeat 50% 0;

  &,
  & > .wrapper {
    display: flex;
    align-items: center;
    .set-value(height; 748);
  }
  .mblock-left {
    .set-value(width; (@min-width-base - (@wrapper-padding-one * 2) - @platform-right-width));
  }
  .mblock-right {
    .set-value(width; @platform-right-width);

    .text-container {
      padding-right: 0;
    }
  }
}
.platform-services {
  .clearfix;
  .set-value(width; @platform-right-width);

  li {
    float: left;
    list-style: none;
    .set-value(width; 248; margin; 3.5 7 3.5 0);

    &:nth-child(3n) {
      margin-right: 0;
    }

    a {
      display: block;
      box-sizing: border-box;
      background: @white;
      border-radius: 10px;
      box-shadow: 0 4px 13px 0 rgba(23, 23, 24, .1);
      .set-value(padding; 40 20 30 20);
      color: @text-color;
      text-decoration: none;
      .text-center;
      transition: all .3s;

      &:hover {
        box-shadow: 0 4px 13px 0 rgba(23, 23, 24, .4);
      }
    }
    .text-container {
      .font-size(13; @paragraph-line-height);
    }
  }
  &-title { // .platform-services-title
    .set-value(height; (@h4-font-size * (@h4-line-height / 100) * @platform-title-max-lines); padding-top; (@platform-title-icon-height + @platform-title-icon-margin-bottom); margin; 0 -10 15 -10);

    &:before {
      display: block;
      position: relative;
      .set-value(width; 80; height; @platform-title-icon-height; margin; ((@platform-title-icon-height + @platform-title-icon-margin-bottom) * -1) auto @platform-title-icon-margin-bottom);
      background-image: url('../images/icons-platform.png');
      background-repeat: no-repeat;
      content: '';
      transition: all .3s;

      a:hover & {
        opacity: .75;
      }
    }
    .for(@i: 1, 10) {
      &-@{i}:before {
        background-position: 0 ((@i - 1) * @platform-title-icon-height * -1);
      }
    }
  }
}

.bottom-list {
  li {
    display: inline-block;
    list-style: none;
    .set-value(padding; 0 0 50 55);

    a {
      color: @grey;
      text-decoration: none;

      &:hover,
      &.active {
        text-decoration: underline;
      }
    }
  }
  &-social { // .bottom-list-social
    .modal & {
      text-align: center;
    }

    li {
      .set-value(padding; 0 0 0 35);
      vertical-align: middle;

      .modal & {
        .set-value(padding; 0 17);
      }

      a {
        display: block;
        background-image: url('../images/icons-social.png');
        background-repeat: no-repeat;
        .size(35; @social-icon-height);
        transition: all .3s;

        &:hover {
          opacity: .75;
          text-decoration: none;
        }
      }
    }
    .for(@i: 1, 3) {
      &-@{i} {
        background-position: 0 ((@i - 1) * @social-icon-height * -1);
      }
    }
  }
}

.help-item {
  display: block;
  background: @white;
  border-radius: 15px;
  box-shadow: 0 0 13px 0 rgba(23, 23, 24, .1);
  .set-value(padding; 50 70; margin-bottom; 35);
  color: @text-color;
  text-decoration: none;
  cursor: pointer;

  &-title { // .help-item-title
    .font-size(24);

    &:after {
      display: block;
      float: right;
      background: url('../images/arrows.png') no-repeat 0 -14px;
      .size(25; 14);
      .set-value(margin-top; 6);
      content: '';
      transition: all .3s;

      .opened & {
        background-position: 0 0;
      }
    }
  }
  .text-container {
    overflow: hidden;
    .set-value(height; 0; margin; 0);
    .font-size(15; @paragraph-line-height);
    transition: all .5s;

    .opened& {
      .set-value(height; auto; padding-top; 40; margin; @paragraph-padding);
    }
  }
}

.footer-counters {
  float: right;
  .set-value(padding; 0 0 0 20);

  img {
    float: left;
    .set-value(margin-left; 10);
  }
}
.footer-copyright {
  &,
  & a {
    color: fade(@grey, 41%);
  }
}


.articles-list {
  .list-hr;

  .articles-title {
    .h4;
  }
}

.contacts-map {
  .pull-right;
  .size(500; 400);

  > * {
    display: block;
    box-sizing: border-box;
    .image-bordered;

    &,
    & img {
      .square(100%) !important;
    }
  }
}
.contacts-title {
  .margin-bottom(5);
  .font-size(@font-size-base; @paragraph-line-height);
  .bold;
}
.contacts-value {
  .set-value(padding-left; 10);
  .font-size(@font-size-base);

  &.contacts-several-values {
    > * {
      &:not(p) {
        .set-value(padding; @paragraph-padding);
      }
      &:not(:last-child) {
        .set-value(padding-bottom; 5);
      }
    }
  }
}

.galleries-list {
  .list-hr;

  .galleries-title-container {
    .clearfix;

    .galleries-title {
      .h4;
    }
    .galleries-all {
      .pull-right;
    }
  }
}
.galleries-foto {
  .clearfix;
  .set-value(margin; 0 0 -8 -8);

  .galleries-foto-item {
    float: left;
    .set-value(margin; 0 0 8 8);

    img {
      .image-bordered;
    }
  }
}

.info-list {
  .list-hr;
  border-top: 1px solid @hr-color-base;
  .set-value(padding-top; 20);

  .info-title {
    .h4;
  }
  .info-announce {
    .text-container;
  }
}

.news-list {
  .list-hr;

  .news-title {
    .h4;
  }
  .news-announce {
    .text-container;
  }
}
.news-date {
  .margin-bottom(10);
  color: @black-50;
}

.resources-form-title {
  .h3;
  border-top: 1px solid @hr-color-base;
  .set-value(padding-top; 20);
}
.resources-list {
  .list-hr;

  .resources-title {
    .margin-bottom(5);
    .bold;
  }
  .resources-announce {
    .set-value(margin-bottom; 5);
    line-height: @paragraph-line-height;

    p {
      .padding-bottom(5);
      line-height: inherit;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .resources-link {
    a {
      .set-value(padding-right; 10);
    }
  }
}

.search-results-list {
  .set-value(margin; 0 0 0 25);
  .font-size(@font-size-base);

  .search-results-title {
    .margin-bottom(5);
    .bold;
  }
  .search-results-announce {
    .set-value(margin-bottom; 5);
    line-height: @paragraph-line-height;

    p {
      .padding-bottom(5);
      line-height: inherit;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .search-results-link {
    .set-value(margin; @paragraph-padding);
  }
}

.sitemap-item {
  .margin-bottom(10);
  .font-size(@font-size-base);
}
.sitemap-title {
  .h4;
}
.sitemap-list {
  .primary-list;
}


// Tables

// Forms
.form-modal {
  .form-title,
  .form-field {
    float: none;
    width: auto;
    padding: 0 0 5px 0;
  }

  .input {
    border: 1px solid #CECECE;
  }
  button,
  .button {
    width: 100%;
    .font-size(13; 100%);
    font-weight: 800;
  }
}

// Buttons
button,
.button {
  display: inline-block;
  box-sizing: border-box;
  background: @blue;
  border: 0;
  border-radius: 10px;
  .set-value(width; 270; padding; 25);
  font-family: @font-family-base;
  line-height: 1;
  color: @white;
  letter-spacing: .5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: background .3s;

  &:hover {
    background: fade(@blue, 75%);
  }
}

// Popups and modal windows
.modal {
  .popup;
  z-index: @modal-zIndex;
  width: @modal-width;
  .set-value(padding; 35 100);
}

// Colors
.black,
.black a {
  color: @black;
}
.white,
.white a {
  color: @white;
}
.red,
.red a {
  color: @red;
}
.green,
.green a {
  color: @green;
}

// Markers
.marker1 {
  .marker('../images/marker1.gif'; 0 0 0 12; 0 4px);
}

// Horizontal lines
.hr1 {
  .hr(@hr-color-base; 20 0);
}
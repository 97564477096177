// Site responsive stylesheets

// Local variables
@mobile-menu-link-lines-width: 15px;
@mobile-menu-link-lines-height: 2px;

// Built-in responsiveness
& when (@isresponsive) {
  // Structure
  @media only all and (max-width: (767px * @reduction-ratio)) {
    main {
      float: none;
      width: 100% !important;
    }
    & when (@sidebar-left-width > 0) {
      aside#left {
        float: none;
        width: 100%;
        padding: 0;
      }
      main {
        padding-left: 0;
      }
    }
    & when (@sidebar-right-width > 0) {
      aside#right {
        float: none;
        width: 100%;
        padding: 0;
      }
      main {
        padding-right: 0;
      }
    }
  }
  @media only all and (max-width: ((@max-width-base - 1) * @reduction-ratio)) {
    .wrapper {
      width: 100%;
      min-width: @min-width-base;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  // Constructions
  & when (@modal-width > 0) {
    @media only all and (max-width: ((@modal-width - 1) * @reduction-ratio)) {
      .modal {
        border-radius: 0;
        width: 100% !important;
        margin: 0;
      }
    }
  }
  @media only all and (max-width: (767px * @reduction-ratio)) {
    .form {
      .form-title {
        float: none;
        .set-value(width; 100%; padding; @paragraph-padding);
      }
      .form-field {
        float: none;
        width: 100%;
      }
    }
  }

  // Classes
  @media only all and (max-width: (767px * @reduction-ratio)) {
    .row-2,
    .row-3 {
      margin: 0 !important;

      .col {
        float: none;
        .set-value(width; 100%; padding; 0; margin; @paragraph-padding);
      }
    }
  }

  // Helpers
  // Hidden/visible
  @media only all and (max-width: (767px * @reduction-ratio)) {
    .hidden-768 {
      display: none !important;
    }
    .visible-768 {
      display: block !important;

      span& {
        display: inline-block !important;
      }
      th&,
      td& {
        display: table-cell !important;
      }
    }
  }
  @media only all and (max-width: (599px * @reduction-ratio)) {
    .hidden-600 {
      display: none !important;
    }
    .visible-600 {
      display: block !important;

      span& {
        display: inline-block !important;
      }
      th&,
      td& {
        display: table-cell !important;
      }
    }
  }
  // Mobile menu link
  .mobile-menu-link {
    position: absolute;
    top: 50%;
    left: -10px;
    .translate(0, -50%);
    .square((@mobile-menu-link-lines-width * 2) false);

    a {
      display: block;
      .square(100%);
      text-decoration: none;
    }
    .bars {
      .set-value(top; 50%; left; 50%);
      .translate(-50%, -50%);

      &,
      &:after,
      &:before {
        display: block;
        position: absolute;
        background: @text-color;
        .size(@mobile-menu-link-lines-width false; @mobile-menu-link-lines-height false);
      }
      &:after,
      &:before {
        position: absolute;
        left: 0;
        content: "";
      }
      &:before {
        top: (@mobile-menu-link-lines-height * 2);
      }
      &:after {
        bottom: (@mobile-menu-link-lines-height * 2);
      }
    }
  }
  // Devices display
  .only-touch {
    .isDesktop & {
      display: none !important;
    }
  }
  .only-desktop {
    .isTouchscreen & {
      display: none !important;
    }
  }
  .only-mobile {
    @media only all and (min-width: (768px * @reduction-ratio)) {
      & {
        display: none !important;
      }
    }
    @media only all and (max-width: (767px * @reduction-ratio)) {
      .isDesktop & {
        display: none !important;
      }
    }
  }
}


// Responsive rules for...
// Retina
@media
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
}

// >=@{max-width-base}px
@media only all and (min-width: @max-width-base) {
}

// <@{max-width-base}px
@media only all and (max-width: (@max-width-base - 1)) {
  html {
    font-size: (@font-size-base * @reduction-ratio);
  }
}

// <768px
@media only all and (max-width: (767px * @reduction-ratio)) {
}

// <=600px
@media only all and (max-width: (600px * @reduction-ratio)) {
}

// <=480px
@media only all and (max-width: (480px * @reduction-ratio)) {
}

// <=380px
@media only all and (max-width: (380px * @reduction-ratio)) {
}
.guestbook-container {
  background: @grey;
}

#guestbook {
  // Variables
  @jcarousel-base-url: '../css/jquery.jcarousel/guestbook';
  @jcarousel-vertical: false;
  @jcarousel-width: (@max-width-base - ((@wrapper-padding-one + 100) * 2));
  @jcarousel-height: 400px;

  @jcarousel-container-background: none;
  @jcarousel-container-border: 0;
  @jcarousel-container-border-radius: 0;
  @jcarousel-container-box-shadow: none;
  @jcarousel-container-padding: 0;
  @jcarousel-container-margin: 0 auto;

  @jcarousel-wrapper-background: none;
  @jcarousel-wrapper-padding: 0;
  @jcarousel-wrapper-margin: 0 auto;

  @jcarousel-items: 1;
  @jcarousel-item-padding: 0;
  @jcarousel-item-margin: 0;
  @jcarousel-item-font: @font-size-base/140% @font-family-base;
  @jcarousel-item-text-align: center;

  @jcarousel-controls-width: 38px;
  @jcarousel-controls-height: 39px;

  @jcarousel-pagination-item-background-image: url('@{jcarousel-base-url}/jcarousel-pagination.png');
  @jcarousel-pagination-item-background-color: transparent;
  @jcarousel-pagination-item-width: 19px;
  @jcarousel-pagination-item-height: @jcarousel-pagination-item-width;
  @jcarousel-pagination-item-padding: 0;
  @jcarousel-pagination-item-margin: 0 10px;
  @jcarousel-pagination-item-font: @font-size-base/@jcarousel-pagination-item-height @font-family-base;
  @jcarousel-pagination-item-color: @link-color;
  @jcarousel-pagination-item-text-transform: none;
  @jcarousel-pagination-item-text-decoration: none;

  @jcarousel-pagination-item-hover-background-color: @jcarousel-pagination-item-background-color;
  @jcarousel-pagination-item-hover-font: @jcarousel-pagination-item-font;
  @jcarousel-pagination-item-hover-color: @jcarousel-pagination-item-color;
  @jcarousel-pagination-item-hover-text-decoration: underline;

  @jcarousel-pagination-item-active-background-color: @jcarousel-pagination-item-background-color;
  @jcarousel-pagination-item-active-font: bold @jcarousel-pagination-item-font;
  @jcarousel-pagination-item-active-color: @jcarousel-pagination-item-color;
  @jcarousel-pagination-item-active-text-decoration: @jcarousel-pagination-item-text-decoration;

  // Styles
  .jcarousel(@jcarousel-vertical); // include common jcarousel styles
  background: @jcarousel-container-background;
  border: @jcarousel-container-border;
  box-shadow: @jcarousel-container-box-shadow;
  .size(@jcarousel-width; @jcarousel-height);
  .set-value(border-radius; @jcarousel-container-border-radius; padding; @jcarousel-container-padding; margin; @jcarousel-container-margin);

  .jcarousel-wrapper {
    background: @jcarousel-wrapper-background;
    .set-value(width; (@jcarousel-width - (@jcarousel-controls-width * 2)); padding; @jcarousel-wrapper-padding; margin; @jcarousel-wrapper-margin);

    ul.jcarousel {
      > li {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        .set-value(padding; @jcarousel-item-padding; margin; @jcarousel-item-margin; font; @jcarousel-item-font);
        text-align: @jcarousel-item-text-align;

        & when not (@jcarousel-vertical) {
          .set-value(width; ((@jcarousel-width - (@jcarousel-controls-width * 2)) / @jcarousel-items));
        }
        & when (@jcarousel-vertical) {
          .set-value(height; ((@jcarousel-height - (@jcarousel-controls-height * 2)) / @jcarousel-items));
        }

        color: @lblue;

        q {
          .font-size(18; @paragraph-line-height);
        }
        figure img {
          display: inline-block;
          .margin-bottom(35);
        }
      }
    }
  }
  .jcarousel-controls {
    .size(@jcarousel-controls-width; @jcarousel-controls-height);
    background-size: cover;

    & when not (@jcarousel-vertical) {
      .set-value(margin-top; (@jcarousel-controls-height / -2));
    }
    & when (@jcarousel-vertical) {
      .set-value(margin-left; (@jcarousel-controls-width / -2));
    }

    &.jcarousel-prev {
      background-image: url('@{jcarousel-base-url}/jcarousel-prev.png');

      & when not (@jcarousel-vertical) {
        left: 0;
      }
      & when (@jcarousel-vertical) {
        top: 0;
      }
    }
    &.jcarousel-next {
      background-image: url('@{jcarousel-base-url}/jcarousel-next.png');

      & when not (@jcarousel-vertical) {
        right: 0;
      }
      & when (@jcarousel-vertical) {
        bottom: 0;
      }
    }

    &:hover {
      & when not (@jcarousel-vertical) {
        .set-value(background-position; (@jcarousel-controls-width * -1) 0);
      }
      & when (@jcarousel-vertical) {
        .set-value(background-position; 0 (@jcarousel-controls-height * -1));
      }
    }
    &:active {
      & when not (@jcarousel-vertical) {
        .set-value(background-position; (@jcarousel-controls-width * -2) 0);
      }
      & when (@jcarousel-vertical) {
        .set-value(background-position; 0 (@jcarousel-controls-height * -2));
      }
    }

    &.jcarousel-controls-inactive {
      & when not (@jcarousel-vertical) {
        .set-value(background-position; (@jcarousel-controls-width * -3) 0);
      }
      & when (@jcarousel-vertical) {
        .set-value(background-position; 0 (@jcarousel-controls-height * -3));
      }
    }
  }
  .jcarousel-pagination {
    .set-value(bottom; 35);
    .text-center;

    @media only all and (max-width: 600px) {
      & {
        .set-value(bottom; 5);
      }
    }

    > a {
      .set-value(padding; @jcarousel-pagination-item-padding; margin; @jcarousel-pagination-item-margin);
    }
    > a when (@jcarousel-pagination-item-background-image = none) {
      background-color: @jcarousel-pagination-item-background-color;
      .set-value(font; @jcarousel-pagination-item-font);
      color: @jcarousel-pagination-item-color;
      text-transform: @jcarousel-pagination-item-text-transform;
      text-decoration: @jcarousel-pagination-item-text-decoration;

      &:hover {
        background-color: @jcarousel-pagination-item-hover-background-color;
        .set-value(font; @jcarousel-pagination-item-hover-font);
        color: @jcarousel-pagination-item-hover-color;
        text-decoration: @jcarousel-pagination-item-hover-text-decoration;
      }
      &.jcarousel-pagination-active {
        background-color: @jcarousel-pagination-item-active-background-color;
        .set-value(font; @jcarousel-pagination-item-active-font);
        color: @jcarousel-pagination-item-active-color;
        text-decoration: @jcarousel-pagination-item-active-text-decoration;
      }
    }
    > a when not (@jcarousel-pagination-item-background-image = none) {
      .text-hide !important;
      background: @jcarousel-pagination-item-background-image no-repeat 0 0;
      background-size: cover;
      .size(@jcarousel-pagination-item-width; @jcarousel-pagination-item-height);

      &:hover {
        .set-value(background-position; (@jcarousel-pagination-item-height * -1) 0);
      }
      &.jcarousel-pagination-active {
        .set-value(background-position; (@jcarousel-pagination-item-height * -2) 0);
      }
    }
  }
}
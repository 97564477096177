// Mixins
.jcarousel(@vertical: false) {
  @direction: @vertical;

  position: relative;
  .center-block;

  &,
  & * {
    box-sizing: border-box;
  }

  .jcarousel-wrapper {
    position: relative;
    overflow: hidden;
    .square(100%);

    ul.jcarousel {
      .clearfix;
      position: relative;
      z-index: 1;

      & when not (@direction) {
        .size(20000em; 100%);
      }
      & when (@direction) {
        .size(100%; 20000em);
      }

      > li {
        list-style: none;
        .square(100%);

        & when not (@direction) {
          float: left;
        }

        > a {
          text-decoration: none;
        }
      }
    }
  }
  .jcarousel-controls {
    display: block;
    position: absolute;
    z-index: 2;
    background-position: 0 0;
    background-repeat: no-repeat;

    & when not (@direction) {
      top: 50%;
    }
    & when (@direction) {
      left: 50%;
    }

    &.jcarousel-controls-inactive {
      cursor: default;
    }
  }
  .jcarousel-pagination {
    position: absolute;
    z-index: 2;
    .size(100%);

    > a {
      display: inline-block;
    }
  }
}

// Import styles (one file per one carousel)
@import "guestbook/jcarousel.guestbook.less";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&subset=cyrillic,cyrillic-ext,latin-ext');
html {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}
body {
  background: #FFFFFF;
  min-width: 1170px;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}
summary {
  display: list-item;
}
audio,
button,
canvas,
img,
input,
select,
svg,
textarea,
video {
  vertical-align: middle;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
img {
  border-style: none;
  -ms-interpolation-mode: bicubic;
}
progress {
  vertical-align: baseline;
}
svg:not(:root) {
  overflow: hidden;
}
a {
  background: transparent;
  color: #0078DD;
  text-decoration: underline;
  -webkit-text-decoration-skip: objects;
  transition: color 0.3s, border 0.3s, background-color 0.3s;
}
a:active,
a:focus,
a:hover,
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline-width: 0;
}
::-moz-selection {
  background: #B3D4FC;
  text-shadow: none;
}
::selection {
  background: #B3D4FC;
  text-shadow: none;
}
h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  text-decoration: underline;
}
h1,
.h1 {
  background: url('../images/h1-bg.png') no-repeat 50% 100%;
  padding: 0px 0px 55px 0px;
  margin: 0px 0px 50px 0px;
  font: 300 60px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 60px;
  line-height: 120%;
  text-transform: uppercase;
}
h1,
.h1,
h1 a,
.h1 a {
  color: #FFFFFF;
}
h2,
.h2 {
  background: url('../images/h1-bg.png') no-repeat 50% 100%;
  padding: 0px 0px 40px 0px;
  margin: 0px 0px 35px 0px;
  font: 300 48px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 48px;
  line-height: 120%;
  text-transform: uppercase;
}
h2,
.h2,
h2 a,
.h2 a {
  color: #FFFFFF;
}
h3,
.h3 {
  background: url('../images/h2-bg.png') no-repeat 0 100%;
  padding: 0px 0px 35px 0px;
  margin: 0px 0px 45px 0px;
  font: 300 34px/140% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 34px;
  line-height: 140%;
  text-transform: none;
}
h3,
.h3,
h3 a,
.h3 a {
  color: #000000;
}
h4,
.h4 {
  background: none;
  padding: 0px;
  margin: 0px;
  font: 600 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}
h4,
.h4,
h4 a,
.h4 a {
  color: #333333;
}
h5,
.h5 {
  background: none;
  padding: 0px;
  margin: 0px 0px 25px 0px;
  font: bold 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  text-transform: none;
}
h5,
.h5,
h5 a,
.h5 a {
  color: #333333;
}
h6,
.h6 {
  background: none;
  padding: 0px;
  margin: 0px 0px 25px 0px;
  font: bold (14px - 1)/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: NaN(14px - 1)/120%;
  line-height: NaN(14px - 1)/120%;
  text-transform: none;
}
h6,
.h6,
h6 a,
.h6 a {
  color: #333333;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  /* autoprefixer: off */
  text-decoration: underline dotted;
}
code,
kbd,
pre,
samp {
  font-family: Courier, 'Courier New', monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
hr {
  /* autoprefixer: off */
  box-sizing: content-box;
  border: 0;
  height: 0;
  overflow: visible;
}
legend {
  /* autoprefixer: off */
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  color: inherit;
  white-space: normal;
}
p {
  padding: 0px 0px 25px 0px;
  line-height: 180%;
}
pre {
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0px;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
ol li {
  list-style: decimal outside;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
[type="checkbox"],
[type="radio"] {
  /* autoprefixer: off */
  box-sizing: border-box;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.input,
select,
textarea {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 0;
  box-shadow: none;
  border-radius: 5px;
  height: 55px;
  padding-left: 25px;
  padding-right: 25px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #000000;
  text-shadow: none;
  transition: border .3s, background .3s;
}
.input:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #333333;
}
.input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #333333;
}
.input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #333333;
}
.input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #333333;
}
.input:focus,
select:focus,
textarea:focus {
  border-color: #000000 !important;
  color: #000000 !important;
  background-color: #ffffff !important;
}
.input[readonly],
select[readonly],
textarea[readonly],
.input[disabled],
select[disabled],
textarea[disabled] {
  border-color: #999999 !important;
  color: #999999 !important;
  background-color: #ffffff !important;
}
textarea {
  height: auto;
  padding: 25px;
  line-height: 180%;
  overflow: auto;
  resize: vertical;
}
textarea[readonly],
textarea[disabled] {
  overflow: auto;
  resize: none;
}
select {
  padding-right: 0;
}
select optgroup {
  font-weight: bold;
}
select option {
  padding: 0px 25px;
}
label {
  cursor: pointer;
}
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    box-shadow: none !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #000000 !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  blockquote,
  img,
  pre,
  tr {
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p {
    orphans: 3;
    widows: 3;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    page-break-after: avoid;
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #999999;
  }
  .input,
  select,
  textarea {
    border-color: #000000;
  }
  aside,
  footer,
  header,
  nav,
  #awst-panel-wrapper,
  .breadcrumbs,
  .pagination,
  .noprint {
    display: none !important;
  }
  main {
    float: none !important;
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
header {
  box-sizing: border-box;
  background: #222222;
  height: 98px;
  padding: 0px;
}
#structure {
  position: relative;
  background: none;
  margin: 0 auto;
  min-height: 100%;
}
#content {
  background: none;
  padding-bottom: 280px;
}
#content .wrapper:before,
#content .wrapper:after {
  content: " ";
  display: table;
}
#content .wrapper:after {
  clear: both;
}
aside,
main {
  box-sizing: border-box;
  float: left;
}
aside#left {
  background: none;
  width: 0px;
  padding: 0px;
}
aside#right {
  background: none;
  width: 0px;
  padding: 0px;
}
main {
  background: none;
  width: calc(100% - 0px - 0px);
  padding: 0px;
}
main.no-aside-left {
  width: calc(100% - 0px);
  padding-left: 0;
}
main.no-aside-right {
  width: calc(100% - 0px);
  padding-right: 0;
}
main.full,
main.no-aside-left.no-aside-right {
  float: none;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
footer {
  position: relative;
  box-sizing: border-box;
  background: url('../images/footer-bg.png') repeat-x 0 0 #FFFFFF;
  padding: 70px 0px 0px;
  margin: 0px auto;
  text-align: left;
  margin-top: -280px;
}
footer > .wrapper {
  height: 280px;
}
.wrapper {
  box-sizing: border-box;
  padding: 0px 10px;
  margin: 0px auto;
  width: 1170px;
}
.breadcrumbs {
  margin: 0px 0px 25px 0px;
  line-height: 0;
  letter-spacing: -1px;
}
.breadcrumbs a,
.breadcrumbs span {
  display: inline-block;
  padding: 0px 5px 0px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #0078DD;
  letter-spacing: normal;
  text-decoration: underline;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs span {
  padding: 0px 5px 0px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  text-decoration: none !important;
}
.breadcrumbs span.breadcrumbs-separator {
  padding: 0px 5px 0px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
}
.breadcrumbs img {
  vertical-align: baseline;
}
.pagination {
  margin: 0px 0px 25px 0px;
}
.pagination ul {
  overflow: hidden;
}
.pagination ul > li {
  float: left;
  padding: 0px 5px;
  list-style: none;
}
.pagination ul > li * {
  display: block;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #0078DD;
  text-decoration: underline;
}
.pagination ul > li *:hover {
  text-decoration: none;
}
.pagination ul > li.page-selected {
  padding: 0px 5px;
}
.pagination ul > li.page-selected * {
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  text-decoration: none !important;
}
.pagination ul > li.page-backward,
.pagination ul > li.page-forward {
  padding: 0px 5px;
}
.pagination ul > li.page-backward *,
.pagination ul > li.page-forward * {
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #0078DD;
}
.form {
  box-sizing: border-box;
  background: none;
  border: 0;
  box-shadow: none;
  border-radius: 0px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.form .form-header {
  display: inline-block;
  background: none;
  border: 0;
  border-radius: 0px;
  padding: 0px;
  font: 13px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 120%;
  color: #919191;
  text-align: left;
}
.form .form-body {
  background: none;
  border: 0;
  border-radius: 0px;
  padding: 0px;
}
.form .form-title {
  display: block;
  float: left;
  width: 27%;
  padding: 3px 3% 0px 0px;
  font: 13px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 120%;
  color: #919191;
  text-transform: none;
  text-align: left;
}
.form .form-field {
  float: left;
  width: 70%;
  padding: 0px;
}
.form .input,
.form select,
.form textarea {
  width: 100%;
}
.form .form-error .input,
.form .form-error select,
.form .form-error textarea {
  background: url(../images/stop-round.png) no-repeat 25px 50% #ff9595;
  border-color: #950000;
  padding-left: 49px;
  padding-right: 25px;
  color: #950000;
}
.form .form-error .input:-moz-placeholder,
.form .form-error select:-moz-placeholder,
.form .form-error textarea:-moz-placeholder {
  opacity: 1;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-error .input::-moz-placeholder,
.form .form-error select::-moz-placeholder,
.form .form-error textarea::-moz-placeholder {
  opacity: 1;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-error .input:-ms-input-placeholder,
.form .form-error select:-ms-input-placeholder,
.form .form-error textarea:-ms-input-placeholder {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-error .input::-webkit-input-placeholder,
.form .form-error select::-webkit-input-placeholder,
.form .form-error textarea::-webkit-input-placeholder {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-error .input:focus,
.form .form-error select:focus,
.form .form-error textarea:focus {
  background-color: #ffafaf;
}
.form .form-success .input,
.form .form-success select,
.form .form-success textarea {
  background: url(../images/accept-green.png) no-repeat 25px 50% #d8f6aa;
  border-color: #5E9010;
  padding-left: 49px;
  padding-right: 25px;
  color: #5E9010;
}
.form .form-success .input:focus,
.form .form-success select:focus,
.form .form-success textarea:focus {
  background-color: #e2f8c0;
}
.form .form-error select,
.form .form-success select {
  padding-left: 24px;
}
.form .form-error textarea,
.form .form-success textarea {
  background-position: 25px 45px;
}
.form span.form-required,
.form .form-required span {
  font: bold 13px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  color: #950000;
}
.form-size-full {
  width: 100% !important;
}
.form-size-three-quarter {
  width: 75% !important;
}
.form-size-two-thirds {
  width: 66% !important;
}
.form-size-half {
  width: 48% !important;
}
.form-size-third {
  width: 32% !important;
}
.form-size-quarter {
  width: 24% !important;
}
.form-size-auto {
  width: auto !important;
}
.form-separator {
  display: inline-block;
  width: 4%;
  text-align: center;
}
.form-hint {
  padding: 10px 0px;
  font: 13px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 120%;
}
.form-submit {
  padding: 0px 0px 30px 0px;
  text-align: center;
}
.form-error-message,
.form-success-message {
  box-sizing: border-box;
  position: relative;
  background-position: 25px 50% !important;
  box-shadow: none;
  border-radius: 0px;
  padding: 5px 5px 5px 49px;
  margin: 0px 0px 25px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
}
.form-field .form-error-message,
.form-field .form-success-message {
  top: -1px;
  margin: 0px;
}
.form-error-message {
  background: url(../images/stop-round.png) no-repeat 25px 50% #ff9595;
  border: 1px solid #950000;
  color: #950000;
}
.form-success-message {
  background: none;
  border: 0;
  color: #5E9010;
}
.list,
.list-hr {
  margin: 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
}
.list li,
.list-hr li {
  margin: 0px 0px 25px 0px;
  list-style: none;
}
.list li.list-element-last,
.list-hr li.list-element-last {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.list li p:last-child,
.list-hr li p:last-child {
  padding: 0;
}
.list-hr li {
  padding: 0px 0px 25px 0px;
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid #0078DD;
}
.list-hr li.list-element-last {
  border: 0;
}
table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
}
table th,
table td {
  padding: 0px 10px 10px 10px;
}
table th p:last-child,
table td p:last-child {
  padding: 0;
}
table th {
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  text-align: left;
}
.table-bordered {
  border-top: 1px solid #0078DD;
  border-left: 1px solid #0078DD;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
}
.table-bordered th,
.table-bordered td {
  border-right: 1px solid #0078DD;
  border-bottom: 1px solid #0078DD;
  padding: 10px;
}
.table-bordered th,
.table-bordered thead td {
  background: #0078DD;
  padding: 10px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #FFFFFF;
  text-align: left;
}
.table-bordered td {
  background: #FFFFFF;
}
.table-striped tr:nth-child(odd) > td {
  background: #CCCCCC;
}
.popup {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background: #FFFFFF;
  border: 0;
  box-shadow: 0 5px 38px 0 rgba(23, 23, 24, 0.31);
  border-radius: 20px;
  width: 300px;
  height: auto;
  text-align: left;
  white-space: normal;
}
.popup .popup-header,
.popup .popup-body,
.popup .popup-footer {
  box-sizing: border-box;
  width: 100%;
}
.popup .popup-header {
  background: url('../images/h1-bg.png') no-repeat 50% 100%;
  border: 0;
  border-radius: 0px;
  height: auto;
  padding: 0px 0px 40px 0px;
  font: 26px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 120%;
  color: #333333;
  text-align: center;
}
.popup .popup-body {
  background: none;
  border: 0;
  border-radius: 0px;
  height: auto;
  padding: 40px 0px 0px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  text-align: left;
}
.popup .popup-footer {
  background: none;
  border: 0;
  border-radius: 0px;
  height: 70px;
  padding: 20px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  text-align: center;
}
.popup .popup-close {
  display: inline-block;
  position: absolute;
  top: 45px;
  right: 35px;
  z-index: 1;
  background: url(../images/button-close.png) no-repeat 0 0;
  width: 17px;
  height: 17px;
  padding: 0px;
  font: 17px/100% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 17px;
  line-height: 100%;
  color: #0078DD;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
}
span.checkbox,
span.radio {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
span.checkbox {
  background: url('../css/jquery.inputs/checkbox-bg.png') no-repeat 0 0;
  width: 27px;
  height: 27px;
}
span.checkbox.checkbox-checked {
  background-position: 0 -27px;
}
span.radio {
  background: url('../css/jquery.inputs/radio-bg.png') no-repeat 0 0;
  width: 13px;
  height: 13px;
}
span.radio.radio-checked {
  background-position: 0 -13px;
}
.guestbook-container {
  background: #292929;
}
#guestbook {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: none;
  border: 0;
  box-shadow: none;
  width: 950px;
  height: 400px;
  border-radius: 0px;
  padding: 0px;
  margin: 0px auto;
}
#guestbook,
#guestbook * {
  box-sizing: border-box;
}
#guestbook .jcarousel-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
#guestbook .jcarousel-wrapper ul.jcarousel {
  position: relative;
  z-index: 1;
  width: 20000em;
  height: 100%;
}
#guestbook .jcarousel-wrapper ul.jcarousel:before,
#guestbook .jcarousel-wrapper ul.jcarousel:after {
  content: " ";
  display: table;
}
#guestbook .jcarousel-wrapper ul.jcarousel:after {
  clear: both;
}
#guestbook .jcarousel-wrapper ul.jcarousel > li {
  list-style: none;
  width: 100%;
  height: 100%;
  float: left;
}
#guestbook .jcarousel-wrapper ul.jcarousel > li > a {
  text-decoration: none;
}
#guestbook .jcarousel-controls {
  display: block;
  position: absolute;
  z-index: 2;
  background-position: 0 0;
  background-repeat: no-repeat;
  top: 50%;
}
#guestbook .jcarousel-controls.jcarousel-controls-inactive {
  cursor: default;
}
#guestbook .jcarousel-pagination {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: auto;
}
#guestbook .jcarousel-pagination > a {
  display: inline-block;
}
#guestbook .jcarousel-wrapper {
  background: none;
  width: 874px;
  padding: 0px;
  margin: 0px auto;
}
#guestbook .jcarousel-wrapper ul.jcarousel > li {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  font: 14px/140% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  width: 874px;
  color: #F5F8FA;
}
#guestbook .jcarousel-wrapper ul.jcarousel > li q {
  font-size: 18px;
  line-height: 180%;
}
#guestbook .jcarousel-wrapper ul.jcarousel > li figure img {
  display: inline-block;
  margin-bottom: 35px;
}
#guestbook .jcarousel-controls {
  width: 38px;
  height: 39px;
  background-size: cover;
  margin-top: -19.5px;
}
#guestbook .jcarousel-controls.jcarousel-prev {
  background-image: url('../css/jquery.jcarousel/guestbook/jcarousel-prev.png');
  left: 0;
}
#guestbook .jcarousel-controls.jcarousel-next {
  background-image: url('../css/jquery.jcarousel/guestbook/jcarousel-next.png');
  right: 0;
}
#guestbook .jcarousel-controls:hover {
  background-position: -38px 0px;
}
#guestbook .jcarousel-controls:active {
  background-position: -76px 0px;
}
#guestbook .jcarousel-controls.jcarousel-controls-inactive {
  background-position: -114px 0px;
}
#guestbook .jcarousel-pagination {
  bottom: 35px;
  text-align: center !important;
}
@media only all and (max-width: 600px) {
  #guestbook .jcarousel-pagination {
    bottom: 5px;
  }
}
#guestbook .jcarousel-pagination > a {
  padding: 0px;
  margin: 0px 10px;
}
#guestbook .jcarousel-pagination > a {
  background-color: transparent !important;
  border: 0 !important;
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background: url('../css/jquery.jcarousel/guestbook/jcarousel-pagination.png') no-repeat 0 0;
  background-size: cover;
  width: 19px;
  height: 19px;
}
#guestbook .jcarousel-pagination > a:hover {
  background-position: -19px 0px;
}
#guestbook .jcarousel-pagination > a.jcarousel-pagination-active {
  background-position: -38px 0px;
}
#uniloader-mouse {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  background: url('../css/jquery.uniloader/ajax.gif') no-repeat 0 0;
  background-size: contain;
  width: 32px;
  height: 32px;
}
#uniloader-mouse.uniloader-apng-supported {
  background: url('../css/jquery.uniloader/ajax.png') no-repeat 0 0;
}
#uniloader-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow-x: auto;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  opacity: 0;
}
#uniloader-overlay-content {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  text-align: center !important;
}
#uniloader-overlay-content .uniloader-overlay-content-text {
  background: url('../css/jquery.uniloader/loader.gif') no-repeat 50% 100%;
  width: 300px;
  height: 27px;
  padding: 0 0 19px 0;
  font: normal 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  color: #FFFFFF;
}
#uniloader-overlay-content .uniloader-overlay-content-text:before {
  content: "Loading. Please wait...";
}
.uniloader-overlay-html {
  overflow: hidden !important;
}
.isDesktop body {
  padding-top: 98px;
}
body.secondary #content {
  background: #F5F8FA;
  padding-bottom: 330px;
}
body.secondary h1,
body.secondary .h1 {
  background: 0;
  padding: 0px;
  margin-bottom: 35px;
}
.h3-block {
  margin-bottom: 60px;
}
.h3-block:only-child {
  margin-bottom: 0px;
}
.h3-block h3,
.h3-block .h3 {
  background: none;
  border-left: 5px solid #0078DD;
  padding: 1px 0px 1px 23px;
  margin: 0px;
  text-transform: uppercase;
}
.h3-block .h3-caption {
  margin-top: 20px;
  font-size: 18px;
  line-height: 120%;
}
.image-bordered {
  box-shadow: 0 25px 46px 0 rgba(23, 23, 24, 0.11);
}
.isDesktop header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 98px;
}
nav li {
  list-style: none;
}
.logo {
  display: block;
  float: left;
  margin: 34px 35px 0px 0px;
  text-decoration: none;
}
footer .logo {
  width: 200px;
  margin-top: 0px;
}
footer .logo span {
  display: block;
  margin-top: 30px;
  line-height: 180%;
  color: #292929;
}
.nav-list li {
  display: inline-block;
  padding-left: 70px;
}
.nav-toolbar.nav-list li {
  padding: 13px 0px 0px 30px;
}
.nav-list li a {
  display: block;
  border-bottom: 3px solid transparent;
  padding-bottom: 27px;
  font-size: 15px;
  line-height: 120%;
  color: #FFFFFF;
  text-decoration: none;
}
.nav-list li a:hover,
.nav-list li a.active {
  border-color: #0078DD;
}
.nav-toolbar.nav-list li a {
  background-repeat: no-repeat;
  background-position: 0 0;
  border: 0;
  padding: 0;
  font-size: 13px;
  line-height: 18px;
  color: #ADADAD;
}
.nav-toolbar.nav-list li a.nav-toolbar-help {
  background-image: url('../images/icon-help.png');
  padding-left: 20px;
}
.nav-toolbar.nav-list li a.nav-toolbar-login {
  background-image: url('../images/icon-login.png');
  padding-left: 26px;
}
.nav-toolbar.nav-list li a.nav-toolbar-register {
  background-image: url('../images/icon-register.png');
  padding-left: 25px;
}
.nav-toolbar.nav-list li a.nav-toolbar-user {
  background-image: url('../images/icon-user.png');
  padding-left: 23px;
}
.nav-toolbar.nav-list li a.nav-toolbar-logout {
  background-image: url('../images/icon-logout.png');
  padding-left: 23px;
}
.nav-toolbar.nav-list li a span {
  border-bottom: 1px solid;
  transition: all .3s;
}
.nav-toolbar.nav-list li a.js-link span {
  border-bottom: 1px dashed;
}
.nav-toolbar.nav-list li a:hover span,
.nav-toolbar.nav-list li a.active span {
  border-color: transparent;
}
.nav-toolbar.nav-list li a.active {
  color: #0078DD !important;
}
.mblock > .wrapper {
  padding-top: 120px;
  padding-bottom: 120px;
}
.mblock-left {
  box-sizing: border-box;
  float: left;
  width: 43%;
  padding-right: 50px;
}
.mblock-left .text-container {
  padding-right: 10px;
}
.mblock-right {
  float: left;
  width: 57%;
}
.mblock-right .text-container {
  padding-right: 20%;
}
.mblock .text-container:last-child {
  margin-bottom: 0px;
}
.mblock-with-background {
  display: flex;
  align-items: center;
}
.mblock-with-background > .wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
.mblock-blue {
  background: #F5F8FA;
}
#promo,
#faq,
#inner {
  background: url('../images/main-bg.jpg') no-repeat 50% 0;
  height: 674px;
  color: #FEFDFD;
  text-align: center !important;
}
#promo .text-container,
#faq .text-container,
#inner .text-container {
  margin-bottom: 70px;
  font-size: 18px;
  line-height: 180%;
}
#faq > .wrapper {
  padding: 0px 155px;
}
#inner {
  background: url('../images/inner-bg.jpg') no-repeat 50% 0;
  height: 319px;
  margin-bottom: 55px;
}
#inner .text-container {
  margin-bottom: 0px;
}
#platform {
  background: url('../images/platform-bg.jpg') no-repeat 50% 0;
}
#platform,
#platform > .wrapper {
  display: flex;
  align-items: center;
  height: 748px;
}
#platform .mblock-left {
  width: 392px;
}
#platform .mblock-right {
  width: 758px;
}
#platform .mblock-right .text-container {
  padding-right: 0;
}
.platform-services {
  width: 758px;
}
.platform-services:before,
.platform-services:after {
  content: " ";
  display: table;
}
.platform-services:after {
  clear: both;
}
.platform-services li {
  float: left;
  list-style: none;
  width: 248px;
  margin: 3.5px 7px 3.5px 0px;
}
.platform-services li:nth-child(3n) {
  margin-right: 0;
}
.platform-services li a {
  display: block;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 13px 0 rgba(23, 23, 24, 0.1);
  padding: 40px 20px 30px 20px;
  color: #333333;
  text-decoration: none;
  text-align: center !important;
  transition: all .3s;
}
.platform-services li a:hover {
  box-shadow: 0 4px 13px 0 rgba(23, 23, 24, 0.4);
}
.platform-services li .text-container {
  font-size: 13px;
  line-height: 180%;
}
.platform-services-title {
  height: 33.6px;
  padding-top: 77px;
  margin: 0px -10px 15px -10px;
}
.platform-services-title:before {
  display: block;
  position: relative;
  width: 80px;
  height: 52px;
  margin: -77px auto 25px;
  background-image: url('../images/icons-platform.png');
  background-repeat: no-repeat;
  content: '';
  transition: all .3s;
}
a:hover .platform-services-title:before {
  opacity: .75;
}
.platform-services-title-1:before {
  background-position: 0 0px;
}
.platform-services-title-2:before {
  background-position: 0 -52px;
}
.platform-services-title-3:before {
  background-position: 0 -104px;
}
.platform-services-title-4:before {
  background-position: 0 -156px;
}
.platform-services-title-5:before {
  background-position: 0 -208px;
}
.platform-services-title-6:before {
  background-position: 0 -260px;
}
.platform-services-title-7:before {
  background-position: 0 -312px;
}
.platform-services-title-8:before {
  background-position: 0 -364px;
}
.platform-services-title-9:before {
  background-position: 0 -416px;
}
.platform-services-title-10:before {
  background-position: 0 -468px;
}
.bottom-list li {
  display: inline-block;
  list-style: none;
  padding: 0px 0px 50px 55px;
}
.bottom-list li a {
  color: #292929;
  text-decoration: none;
}
.bottom-list li a:hover,
.bottom-list li a.active {
  text-decoration: underline;
}
.modal .bottom-list-social {
  text-align: center;
}
.bottom-list-social li {
  padding: 0px 0px 0px 35px;
  vertical-align: middle;
}
.modal .bottom-list-social li {
  padding: 0px 17px;
}
.bottom-list-social li a {
  display: block;
  background-image: url('../images/icons-social.png');
  background-repeat: no-repeat;
  width: 35px;
  height: 27px;
  transition: all .3s;
}
.bottom-list-social li a:hover {
  opacity: .75;
  text-decoration: none;
}
.bottom-list-social-1 {
  background-position: 0 0px;
}
.bottom-list-social-2 {
  background-position: 0 -27px;
}
.bottom-list-social-3 {
  background-position: 0 -54px;
}
.help-item {
  display: block;
  background: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0 0 13px 0 rgba(23, 23, 24, 0.1);
  padding: 50px 70px;
  margin-bottom: 35px;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
}
.help-item-title {
  font-size: 24px;
  line-height: 120%;
}
.help-item-title:after {
  display: block;
  float: right;
  background: url('../images/arrows.png') no-repeat 0 -14px;
  width: 25px;
  height: 14px;
  margin-top: 6px;
  content: '';
  transition: all .3s;
}
.opened .help-item-title:after {
  background-position: 0 0;
}
.help-item .text-container {
  overflow: hidden;
  height: 0px;
  margin: 0px;
  font-size: 15px;
  line-height: 180%;
  transition: all .5s;
}
.opened.help-item .text-container {
  height: auto;
  padding-top: 40px;
  margin: 0px 0px 25px 0px;
}
.footer-counters {
  float: right;
  padding: 0px 0px 0px 20px;
}
.footer-counters img {
  float: left;
  margin-left: 10px;
}
.footer-copyright,
.footer-copyright a {
  color: rgba(41, 41, 41, 0.41);
}
.articles-list {
  margin: 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
}
.articles-list li {
  margin: 0px 0px 25px 0px;
  list-style: none;
}
.articles-list li.list-element-last {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.articles-list li p:last-child {
  padding: 0;
}
.articles-list li {
  padding: 0px 0px 25px 0px;
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid #0078DD;
}
.articles-list li.list-element-last {
  border: 0;
}
.articles-list .articles-title {
  background: none;
  padding: 0px;
  margin: 0px;
  font: 600 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}
.articles-list .articles-title a {
  text-decoration: underline;
}
.articles-list .articles-title,
.articles-list .articles-title a {
  color: #333333;
}
.contacts-map {
  float: right;
  margin: 0px 0px 10px 15px;
  width: 500px;
  height: 400px;
}
table.contacts-map,
th.contacts-map,
td.contacts-map {
  float: none;
  margin: 0;
  text-align: right !important;
}
.contacts-map > * {
  display: block;
  box-sizing: border-box;
  box-shadow: 0 25px 46px 0 rgba(23, 23, 24, 0.11);
  border: 0px;
  padding: 0px;
}
.contacts-map > *,
.contacts-map > * img {
  width: 100% !important;
  height: 100% !important;
}
.contacts-title {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 180%;
  font-weight: bold;
}
.contacts-value {
  padding-left: 10px;
  font-size: 14px;
  line-height: 120%;
}
.contacts-value.contacts-several-values > *:not(p) {
  padding: 0px 0px 25px 0px;
}
.contacts-value.contacts-several-values > *:not(:last-child) {
  padding-bottom: 5px;
}
.galleries-list {
  margin: 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
}
.galleries-list li {
  margin: 0px 0px 25px 0px;
  list-style: none;
}
.galleries-list li.list-element-last {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.galleries-list li p:last-child {
  padding: 0;
}
.galleries-list li {
  padding: 0px 0px 25px 0px;
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid #0078DD;
}
.galleries-list li.list-element-last {
  border: 0;
}
.galleries-list .galleries-title-container:before,
.galleries-list .galleries-title-container:after {
  content: " ";
  display: table;
}
.galleries-list .galleries-title-container:after {
  clear: both;
}
.galleries-list .galleries-title-container .galleries-title {
  background: none;
  padding: 0px;
  margin: 0px;
  font: 600 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}
.galleries-list .galleries-title-container .galleries-title a {
  text-decoration: underline;
}
.galleries-list .galleries-title-container .galleries-title,
.galleries-list .galleries-title-container .galleries-title a {
  color: #333333;
}
.galleries-list .galleries-title-container .galleries-all {
  float: right;
  margin: 0px 0px 10px 15px;
}
table.galleries-list .galleries-title-container .galleries-all,
th.galleries-list .galleries-title-container .galleries-all,
td.galleries-list .galleries-title-container .galleries-all {
  float: none;
  margin: 0;
  text-align: right !important;
}
.galleries-foto {
  margin: 0px 0px -8px -8px;
}
.galleries-foto:before,
.galleries-foto:after {
  content: " ";
  display: table;
}
.galleries-foto:after {
  clear: both;
}
.galleries-foto .galleries-foto-item {
  float: left;
  margin: 0px 0px 8px 8px;
}
.galleries-foto .galleries-foto-item img {
  box-shadow: 0 25px 46px 0 rgba(23, 23, 24, 0.11);
  border: 0px;
  padding: 0px;
}
.info-list {
  margin: 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  border-top: 1px solid #0078DD;
  padding-top: 20px;
}
.info-list li {
  margin: 0px 0px 25px 0px;
  list-style: none;
}
.info-list li.list-element-last {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.info-list li p:last-child {
  padding: 0;
}
.info-list li {
  padding: 0px 0px 25px 0px;
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid #0078DD;
}
.info-list li.list-element-last {
  border: 0;
}
.info-list .info-title {
  background: none;
  padding: 0px;
  margin: 0px;
  font: 600 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}
.info-list .info-title a {
  text-decoration: underline;
}
.info-list .info-title,
.info-list .info-title a {
  color: #333333;
}
.info-list .info-announce {
  margin: 0px 0px 25px 0px;
  font-size: 14px;
  line-height: 180%;
}
.info-list .info-announce p {
  line-height: inherit;
}
.info-list .info-announce p:last-child {
  padding-bottom: 0;
}
.info-list .info-announce ul {
  margin: 0px 0px 25px 0px;
}
.info-list .info-announce ul li {
  margin: 0px 0px 10px 10px;
  list-style: disc inside;
}
.info-list .info-announce ul:last-child,
.info-list .info-announce ol:last-child {
  margin-bottom: 0;
}
.info-list .info-announce ul:last-child li:last-child,
.info-list .info-announce ol:last-child li:last-child {
  margin-bottom: 0;
}
.info-list .info-announce:before,
.info-list .info-announce:after {
  content: " ";
  display: table;
}
.info-list .info-announce:after {
  clear: both;
}
.news-list {
  margin: 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
}
.news-list li {
  margin: 0px 0px 25px 0px;
  list-style: none;
}
.news-list li.list-element-last {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.news-list li p:last-child {
  padding: 0;
}
.news-list li {
  padding: 0px 0px 25px 0px;
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid #0078DD;
}
.news-list li.list-element-last {
  border: 0;
}
.news-list .news-title {
  background: none;
  padding: 0px;
  margin: 0px;
  font: 600 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}
.news-list .news-title a {
  text-decoration: underline;
}
.news-list .news-title,
.news-list .news-title a {
  color: #333333;
}
.news-list .news-announce {
  margin: 0px 0px 25px 0px;
  font-size: 14px;
  line-height: 180%;
}
.news-list .news-announce p {
  line-height: inherit;
}
.news-list .news-announce p:last-child {
  padding-bottom: 0;
}
.news-list .news-announce ul {
  margin: 0px 0px 25px 0px;
}
.news-list .news-announce ul li {
  margin: 0px 0px 10px 10px;
  list-style: disc inside;
}
.news-list .news-announce ul:last-child,
.news-list .news-announce ol:last-child {
  margin-bottom: 0;
}
.news-list .news-announce ul:last-child li:last-child,
.news-list .news-announce ol:last-child li:last-child {
  margin-bottom: 0;
}
.news-list .news-announce:before,
.news-list .news-announce:after {
  content: " ";
  display: table;
}
.news-list .news-announce:after {
  clear: both;
}
.news-date {
  margin-bottom: 10px;
  color: #808080;
}
.resources-form-title {
  background: url('../images/h2-bg.png') no-repeat 0 100%;
  padding: 0px 0px 35px 0px;
  margin: 0px 0px 45px 0px;
  font: 300 34px/140% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 34px;
  line-height: 140%;
  text-transform: none;
  border-top: 1px solid #0078DD;
  padding-top: 20px;
}
.resources-form-title a {
  text-decoration: underline;
}
.resources-form-title,
.resources-form-title a {
  color: #000000;
}
.resources-list {
  margin: 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
}
.resources-list li {
  margin: 0px 0px 25px 0px;
  list-style: none;
}
.resources-list li.list-element-last {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.resources-list li p:last-child {
  padding: 0;
}
.resources-list li {
  padding: 0px 0px 25px 0px;
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid #0078DD;
}
.resources-list li.list-element-last {
  border: 0;
}
.resources-list .resources-title {
  margin-bottom: 5px;
  font-weight: bold;
}
.resources-list .resources-announce {
  margin-bottom: 5px;
  line-height: 180%;
}
.resources-list .resources-announce p {
  padding-bottom: 5px;
  line-height: inherit;
}
.resources-list .resources-announce p:last-child {
  padding-bottom: 0;
}
.resources-list .resources-link a {
  padding-right: 10px;
}
.search-results-list {
  margin: 0px 0px 0px 25px;
  font-size: 14px;
  line-height: 120%;
}
.search-results-list .search-results-title {
  margin-bottom: 5px;
  font-weight: bold;
}
.search-results-list .search-results-announce {
  margin-bottom: 5px;
  line-height: 180%;
}
.search-results-list .search-results-announce p {
  padding-bottom: 5px;
  line-height: inherit;
}
.search-results-list .search-results-announce p:last-child {
  padding-bottom: 0;
}
.search-results-list .search-results-link {
  margin: 0px 0px 25px 0px;
}
.sitemap-item {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 120%;
}
.sitemap-title {
  background: none;
  padding: 0px;
  margin: 0px;
  font: 600 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}
.sitemap-title a {
  text-decoration: underline;
}
.sitemap-title,
.sitemap-title a {
  color: #333333;
}
.sitemap-list {
  margin: 0px 0px 25px 0px;
}
.sitemap-list li {
  margin: 0px 0px 10px 10px;
  list-style: disc inside;
}
.form-modal .form-title,
.form-modal .form-field {
  float: none;
  width: auto;
  padding: 0 0 5px 0;
}
.form-modal .input {
  border: 1px solid #CECECE;
}
.form-modal button,
.form-modal .button {
  width: 100%;
  font-size: 13px;
  line-height: 100%;
  font-weight: 800;
}
button,
.button {
  display: inline-block;
  box-sizing: border-box;
  background: #0078DD;
  border: 0;
  border-radius: 10px;
  width: 270px;
  padding: 25px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  line-height: 1;
  color: #FFFFFF;
  letter-spacing: .5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: background .3s;
}
button:hover,
.button:hover {
  background: rgba(0, 120, 221, 0.75);
}
.modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background: #FFFFFF;
  border: 0;
  box-shadow: 0 5px 38px 0 rgba(23, 23, 24, 0.31);
  border-radius: 20px;
  width: 300px;
  height: auto;
  text-align: left;
  white-space: normal;
  z-index: 1001;
  width: 370px;
  padding: 35px 100px;
}
.modal .popup-header,
.modal .popup-body,
.modal .popup-footer {
  box-sizing: border-box;
  width: 100%;
}
.modal .popup-header {
  background: url('../images/h1-bg.png') no-repeat 50% 100%;
  border: 0;
  border-radius: 0px;
  height: auto;
  padding: 0px 0px 40px 0px;
  font: 26px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 120%;
  color: #333333;
  text-align: center;
}
.modal .popup-body {
  background: none;
  border: 0;
  border-radius: 0px;
  height: auto;
  padding: 40px 0px 0px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  text-align: left;
}
.modal .popup-footer {
  background: none;
  border: 0;
  border-radius: 0px;
  height: 70px;
  padding: 20px 0px;
  font: 14px/120% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: #333333;
  text-align: center;
}
.modal .popup-close {
  display: inline-block;
  position: absolute;
  top: 45px;
  right: 35px;
  z-index: 1;
  background: url(../images/button-close.png) no-repeat 0 0;
  width: 17px;
  height: 17px;
  padding: 0px;
  font: 17px/100% 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 17px;
  line-height: 100%;
  color: #0078DD;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
}
.black,
.black a {
  color: #000000;
}
.white,
.white a {
  color: #FFFFFF;
}
.red,
.red a {
  color: #950000;
}
.green,
.green a {
  color: #5E9010;
}
.marker1 {
  background: url('../images/marker1.gif') no-repeat;
  background-position: 0px 4px;
  padding: 0px 0px 0px 12px;
}
.hr1 {
  background: #0078DD;
  height: 1px;
  margin: 20px 0px;
}
.text-container {
  margin: 0px 0px 25px 0px;
  font-size: 14px;
  line-height: 180%;
}
.text-container p {
  line-height: inherit;
}
.text-container p:last-child {
  padding-bottom: 0;
}
.text-container ul {
  margin: 0px 0px 25px 0px;
}
.text-container ul li {
  margin: 0px 0px 10px 10px;
  list-style: disc inside;
}
.text-container ul:last-child,
.text-container ol:last-child {
  margin-bottom: 0;
}
.text-container ul:last-child li:last-child,
.text-container ol:last-child li:last-child {
  margin-bottom: 0;
}
.s8 {
  font-size: 8px !important;
  line-height: 120% !important;
}
p.s8 {
  font-size: 8px !important;
  line-height: 180% !important;
}
.s9 {
  font-size: 9px !important;
  line-height: 120% !important;
}
p.s9 {
  font-size: 9px !important;
  line-height: 180% !important;
}
.s10 {
  font-size: 10px !important;
  line-height: 120% !important;
}
p.s10 {
  font-size: 10px !important;
  line-height: 180% !important;
}
.s11 {
  font-size: 11px !important;
  line-height: 120% !important;
}
p.s11 {
  font-size: 11px !important;
  line-height: 180% !important;
}
.s12 {
  font-size: 12px !important;
  line-height: 120% !important;
}
p.s12 {
  font-size: 12px !important;
  line-height: 180% !important;
}
.s13 {
  font-size: 13px !important;
  line-height: 120% !important;
}
p.s13 {
  font-size: 13px !important;
  line-height: 180% !important;
}
.s14 {
  font-size: 14px !important;
  line-height: 120% !important;
}
p.s14 {
  font-size: 14px !important;
  line-height: 180% !important;
}
.s15 {
  font-size: 15px !important;
  line-height: 120% !important;
}
p.s15 {
  font-size: 15px !important;
  line-height: 180% !important;
}
.s16 {
  font-size: 16px !important;
  line-height: 120% !important;
}
p.s16 {
  font-size: 16px !important;
  line-height: 180% !important;
}
.s17 {
  font-size: 17px !important;
  line-height: 120% !important;
}
p.s17 {
  font-size: 17px !important;
  line-height: 180% !important;
}
.s18 {
  font-size: 18px !important;
  line-height: 120% !important;
}
p.s18 {
  font-size: 18px !important;
  line-height: 180% !important;
}
.s19 {
  font-size: 19px !important;
  line-height: 120% !important;
}
p.s19 {
  font-size: 19px !important;
  line-height: 180% !important;
}
.s20 {
  font-size: 20px !important;
  line-height: 120% !important;
}
p.s20 {
  font-size: 20px !important;
  line-height: 180% !important;
}
.s21 {
  font-size: 21px !important;
  line-height: 120% !important;
}
p.s21 {
  font-size: 21px !important;
  line-height: 180% !important;
}
.s22 {
  font-size: 22px !important;
  line-height: 120% !important;
}
p.s22 {
  font-size: 22px !important;
  line-height: 180% !important;
}
.s23 {
  font-size: 23px !important;
  line-height: 120% !important;
}
p.s23 {
  font-size: 23px !important;
  line-height: 180% !important;
}
.s24 {
  font-size: 24px !important;
  line-height: 120% !important;
}
p.s24 {
  font-size: 24px !important;
  line-height: 180% !important;
}
.s25 {
  font-size: 25px !important;
  line-height: 120% !important;
}
p.s25 {
  font-size: 25px !important;
  line-height: 180% !important;
}
.s26 {
  font-size: 26px !important;
  line-height: 120% !important;
}
p.s26 {
  font-size: 26px !important;
  line-height: 180% !important;
}
.s27 {
  font-size: 27px !important;
  line-height: 120% !important;
}
p.s27 {
  font-size: 27px !important;
  line-height: 180% !important;
}
.s28 {
  font-size: 28px !important;
  line-height: 120% !important;
}
p.s28 {
  font-size: 28px !important;
  line-height: 180% !important;
}
.s29 {
  font-size: 29px !important;
  line-height: 120% !important;
}
p.s29 {
  font-size: 29px !important;
  line-height: 180% !important;
}
.s30 {
  font-size: 30px !important;
  line-height: 120% !important;
}
p.s30 {
  font-size: 30px !important;
  line-height: 180% !important;
}
.s31 {
  font-size: 31px !important;
  line-height: 120% !important;
}
p.s31 {
  font-size: 31px !important;
  line-height: 180% !important;
}
.s32 {
  font-size: 32px !important;
  line-height: 120% !important;
}
p.s32 {
  font-size: 32px !important;
  line-height: 180% !important;
}
.s33 {
  font-size: 33px !important;
  line-height: 120% !important;
}
p.s33 {
  font-size: 33px !important;
  line-height: 180% !important;
}
.s34 {
  font-size: 34px !important;
  line-height: 120% !important;
}
p.s34 {
  font-size: 34px !important;
  line-height: 180% !important;
}
.s35 {
  font-size: 35px !important;
  line-height: 120% !important;
}
p.s35 {
  font-size: 35px !important;
  line-height: 180% !important;
}
.s36 {
  font-size: 36px !important;
  line-height: 120% !important;
}
p.s36 {
  font-size: 36px !important;
  line-height: 180% !important;
}
.s37 {
  font-size: 37px !important;
  line-height: 120% !important;
}
p.s37 {
  font-size: 37px !important;
  line-height: 180% !important;
}
.s38 {
  font-size: 38px !important;
  line-height: 120% !important;
}
p.s38 {
  font-size: 38px !important;
  line-height: 180% !important;
}
.s39 {
  font-size: 39px !important;
  line-height: 120% !important;
}
p.s39 {
  font-size: 39px !important;
  line-height: 180% !important;
}
.s40 {
  font-size: 40px !important;
  line-height: 120% !important;
}
p.s40 {
  font-size: 40px !important;
  line-height: 180% !important;
}
.normal {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.hover,
.hover a {
  text-decoration: none !important;
}
.hover:hover,
.hover a:hover {
  text-decoration: underline !important;
}
.unhover,
.unhover a {
  text-decoration: underline !important;
}
.unhover:hover,
.unhover a:hover {
  text-decoration: none !important;
}
.none,
.none a {
  border-bottom: 0 !important;
  text-decoration: none !important;
}
.underline,
.underline a {
  border-bottom: 1px solid;
  text-decoration: none !important;
  transition: border .3s;
}
.hover.underline,
.hover.underline a {
  border-bottom: 0;
}
.hover.underline:hover,
.hover.underline a:hover {
  border-bottom: 1px solid;
}
.unhover.underline:hover,
.unhover.underline a:hover {
  border-bottom: 0;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.nobr {
  white-space: nowrap;
}
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  white-space: nowrap;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  clip-path: none;
  width: auto;
  height: auto;
  margin: 0;
  white-space: inherit;
}
.image-responsive {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
  height: auto;
}
.image-bordered {
  border: 0px;
  padding: 0px;
}
.image-circle {
  border-radius: 50%;
}
.embed-responsive {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.row-2 {
  box-sizing: border-box;
  margin: 0px -10px;
}
.row-2:before,
.row-2:after {
  content: " ";
  display: table;
}
.row-2:after {
  clear: both;
}
.row-2 .col {
  box-sizing: border-box;
  position: relative;
  float: left;
  min-height: 1px;
  width: 50%;
  padding: 0px 10px;
}
.row-3 {
  box-sizing: border-box;
  margin: 0px -10px;
}
.row-3:before,
.row-3:after {
  content: " ";
  display: table;
}
.row-3:after {
  clear: both;
}
.row-3 .col {
  box-sizing: border-box;
  position: relative;
  float: left;
  min-height: 1px;
  width: 33.33333333%;
  padding: 0px 10px;
}
.table-responsive {
  overflow: hidden;
  overflow-x: auto;
}
.table-responsive:before,
.table-responsive:after {
  content: " ";
  display: table;
}
.table-responsive:after {
  clear: both;
}
.table-responsive table {
  margin-bottom: 0;
}
.nowrap {
  white-space: nowrap !important;
}
.valign-top {
  vertical-align: top !important;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.hidden {
  display: none !important;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.center-block-content {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.center-block-content > div {
  display: block;
  position: relative;
  float: left;
  left: 50%;
}
.center-block-content > div > div {
  display: block;
  position: relative;
  float: left;
  right: 50%;
}
.justify-block-content {
  line-height: 0;
  letter-spacing: -1px;
  text-align: justify;
}
.justify-block-content:after {
  display: inline-block;
  visibility: hidden;
  width: 100%;
  content: ".";
}
.justify-block-content > * {
  display: inline-block;
  width: auto;
  line-height: 120%;
  letter-spacing: normal;
  vertical-align: top;
}
.left {
  float: left;
}
.right {
  float: right;
}
.pull-left {
  float: left;
  margin: 0px 15px 10px 0px;
}
table.pull-left,
th.pull-left,
td.pull-left {
  float: none;
  margin: 0;
  text-align: left !important;
}
.pull-right {
  float: right;
  margin: 0px 0px 10px 15px;
}
table.pull-right,
th.pull-right,
td.pull-right {
  float: none;
  margin: 0;
  text-align: right !important;
}
.primary-list {
  margin: 0px 0px 25px 0px;
}
.primary-list li {
  margin: 0px 0px 10px 10px;
  list-style: disc inside;
}
.m0 {
  margin-bottom: 0px !important;
}
.m1 {
  margin-bottom: 1px !important;
}
.m2 {
  margin-bottom: 2px !important;
}
.m3 {
  margin-bottom: 3px !important;
}
.m4 {
  margin-bottom: 4px !important;
}
.m5 {
  margin-bottom: 5px !important;
}
.m6 {
  margin-bottom: 6px !important;
}
.m7 {
  margin-bottom: 7px !important;
}
.m8 {
  margin-bottom: 8px !important;
}
.m9 {
  margin-bottom: 9px !important;
}
.m10 {
  margin-bottom: 10px !important;
}
.m15 {
  margin-bottom: 15px !important;
}
.m20 {
  margin-bottom: 20px !important;
}
.m25 {
  margin-bottom: 25px !important;
}
.m30 {
  margin-bottom: 30px !important;
}
.m35 {
  margin-bottom: 35px !important;
}
.m40 {
  margin-bottom: 40px !important;
}
.m45 {
  margin-bottom: 45px !important;
}
.m50 {
  margin-bottom: 50px !important;
}
.p0 {
  padding-bottom: 0px !important;
}
.p1 {
  padding-bottom: 1px !important;
}
.p2 {
  padding-bottom: 2px !important;
}
.p3 {
  padding-bottom: 3px !important;
}
.p4 {
  padding-bottom: 4px !important;
}
.p5 {
  padding-bottom: 5px !important;
}
.p6 {
  padding-bottom: 6px !important;
}
.p7 {
  padding-bottom: 7px !important;
}
.p8 {
  padding-bottom: 8px !important;
}
.p9 {
  padding-bottom: 9px !important;
}
.p10 {
  padding-bottom: 10px !important;
}
.p15 {
  padding-bottom: 15px !important;
}
.p20 {
  padding-bottom: 20px !important;
}
.p25 {
  padding-bottom: 25px !important;
}
.p30 {
  padding-bottom: 30px !important;
}
.p35 {
  padding-bottom: 35px !important;
}
.p40 {
  padding-bottom: 40px !important;
}
.p45 {
  padding-bottom: 45px !important;
}
.p50 {
  padding-bottom: 50px !important;
}
.clearfix:before,
.nofloat:before,
.text-container:before,
.clearfix:after,
.nofloat:after,
.text-container:after {
  content: " ";
  display: table;
}
.clearfix:after,
.nofloat:after,
.text-container:after {
  clear: both;
}
.clear {
  clear: both;
}
.float-none {
  overflow: hidden;
}
@media only all and (max-width: 1169px) {
  html {
    font-size: 14px;
  }
}

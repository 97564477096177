// Variables
@inputs-base-url: '../css/jquery.inputs';

@inputs-checkbox-width: 27px;
@inputs-checkbox-height: @inputs-checkbox-width;

@inputs-radio-width: 13px;
@inputs-radio-height: @inputs-radio-width;

// Styles
span.checkbox,
span.radio {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
span.checkbox {
  background: url('@{inputs-base-url}/checkbox-bg.png') no-repeat 0 0;
  .size(@inputs-checkbox-width; @inputs-checkbox-height);

  &.checkbox-checked {
    background-position: 0 -@inputs-checkbox-height;
  }
}
span.radio {
  background: url('@{inputs-base-url}/radio-bg.png') no-repeat 0 0;
  .size(@inputs-radio-width; @inputs-radio-height);

  &.radio-checked {
    background-position: 0 -@inputs-radio-height;
  }
}
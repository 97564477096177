// Variables
@uniloader-base-url: '../css/jquery.uniloader';
@uniloader-zIndex: @modal-zIndex;

@uniloader-mouse-background: url('@{uniloader-base-url}/ajax.gif') no-repeat 0 0;
@uniloader-mouse-apng-background: url('@{uniloader-base-url}/ajax.png') no-repeat 0 0;
@uniloader-mouse-width: 32px;
@uniloader-mouse-height: @uniloader-mouse-width;

@uniloader-overlay-background: rgba(0, 0, 0, .75);
@uniloader-overlay-content-background: url('@{uniloader-base-url}/loader.gif') no-repeat 50% 100%;
@uniloader-overlay-content-width: 300px;
@uniloader-overlay-content-height: 27px;
@uniloader-overlay-content-padding: 0 0 19px 0;
@uniloader-overlay-content-font: normal @font-size-base/@line-height-base @font-family-base;
@uniloader-overlay-content-color: @white;
@uniloader-overlay-content-text: Loading. Please wait...;

// Styles
#uniloader-mouse {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: @uniloader-zIndex;
  background: @uniloader-mouse-background;
  background-size: contain;
  .size(@uniloader-mouse-width; @uniloader-mouse-height);

  &.uniloader-apng-supported {
    background: @uniloader-mouse-apng-background;
  }
}

#uniloader-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: (@uniloader-zIndex - 1);
  overflow-x: auto;
  overflow-y: scroll;
  background: @uniloader-overlay-background;
  .square(100%);
  opacity: 0;
}

#uniloader-overlay-content {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: @uniloader-zIndex;
  .text-center;

  .uniloader-overlay-content-text {
    background: @uniloader-overlay-content-background;
    .size(@uniloader-overlay-content-width; @uniloader-overlay-content-height);
    padding: @uniloader-overlay-content-padding;
    font: @uniloader-overlay-content-font;
    color: @uniloader-overlay-content-color;

    &:before {
      content: "@{uniloader-overlay-content-text}";
     }
  }
}

.uniloader-overlay-html {
  overflow: hidden !important;
}